import axios from '../utils/axios';
import {default as axiosBase} from 'axios';
import {IFiltersPagination} from "../types/store";
import company from "@core/services/company";
import FileSaver from "file-saver";

const BASE = (company: string) => `/company/${company}/stocktaking`;

interface IFilters {
  target: string,
  user: string,
  data: Date
}

export default {
  getAll: async (company: string, pagination: IFiltersPagination, filters: IFilters) => (await axios.get(BASE(company), {params: {...pagination, ...filters}})).data,
  getItem: async (company: string, id: string, pagination: IFiltersPagination) => (await axios.get(BASE(company) + `/` + id, {params: pagination})).data,
  getReportLink: async (company: string, id: string) => (await axios.get(`${BASE(company)}/${id}/report`)).data,
  downloadReport: async (link: string) => {
    const { data } = await axiosBase.get(link, { responseType: "blob"});
    return FileSaver.saveAs(data, `Очет_по_Инвентаризации.xlsx`)
  },
  remove: async (company: string, id: string) => (await axios.delete(BASE(company) + '/' + id)).data,
}
