export enum ItemsActions {
  GET_ITEMS = 'get_items',
  GET_ITEMS_MOBILE = 'get_items_mobile',
  SET_ITEMS_MOBILE = 'set_items_mobile',
  GET_ITEM = 'get_item',
  GET_ITEM_TXS = 'get_item_txs',
  SET_ITEM_TXS = 'set_item_txs',
  CLEAR_TXS_DATA = 'clear_item_txs',
  SET_ITEM_TXS_PAGINATION = 'set_item_txs_pagination',
  GET_ITEM_TRANSFERS = 'get_item_transfers',
  SET_ITEMS = 'set_items',
  TOGGLE_FILTERS = 'toggle_filters',
  SET_FILTERS = 'set_filters',
  SET_SORT = 'set_sort',
  CLEAR_FILTERS = 'clear_filters',
  DELETE_FILTERS = 'delete_filters',
  DELETE_ITEMS = 'delete_items',
  SET_LOADING = 'set_items_loading',
  SET_TX_LOADING = 'set_items_tx_loading',
  SET_ITEM = 'set_item',
  UPDATE_ITEM = 'updated_item',
  CLEAR_ITEM = 'clear_item',
  SET_PAGINATION = 'set_pagination',
  CREATE_ITEM = 'create_item',
  EDIT_ITEM = 'edit_item',
  REPAIR_ITEM = 'repair_item',
  REPAIR_BACK_ITEM = 'repair_back_item',
  BAN_ITEM = 'ban_item',
  CLEAR_ITEMS = 'clear_items',
  REMOVE_ITEM = 'remove_item',
  REMOVE_ITEM_ALL = 'remove_all_items',
  MOVE_BULK = 'move_items_bulk',
  CHANGE_ITEM_LOCATION_ALL = 'change_item_location_all',
  ADD_ITEMS = 'add_items_to_parent',
  REMOVE_ITEMS = 'remove_items_from_parent',
  GENERATE_QR = 'item_generate_qr',
  DOWNLOAD_CODE = 'item_download_qr',
  UPLOAD_PHOTOS = 'item_upload_photos',
  UPDATE_ITEM_PHOTOS = 'item_update_item_photos',
  SET_MAIN_ITEM_PHOTO = 'set_main_item_photo',
  DELETE_ITEM_PHOTO = 'delete_item_photo',
  CREATE_REPORT = 'create_items_report',
  MERGE_ITEM = 'merge_item',
  // GET_ITEM_COUNTERS_DATA = 'get_item_counters_data',
  SET_ITEM_COUNTERS_DATA = 'set_item_counters_data',
  SET_ITEM_COUNTERS_DATA_LOADING = 'set_item_counters_data_loading',
  DUPLICATE_ITEM = 'duplicate_item',

}
