export enum StocktakingActions {
  GET_ITEMS = 'get_stocktaking',
  GET_ITEM = 'get_stocktaking_item',
  SET_ITEMS = 'set_stocktaking',
  TOGGLE_FILTERS = 'toggle_stocktaking_filters',
  SET_FILTERS = 'set_stocktaking_filters',
  CLEAR_FILTERS = 'clear_stocktaking_filters',
  DELETE_FILTERS = 'delete_stocktaking_filters',
  SET_LOADING = 'set_stocktaking_loading',
  SET_ITEM = 'set_stocktaking_item',
  CLEAR_ITEM = 'clear_stocktaking',
  SET_PAGINATION = 'set_stocktaking_pagination',
  UPDATE_ITEM = 'update_item',
  DOWNLOAD_STOCKTAKING_REPORT = 'download_stocktaking_report',
  REMOVE_STOCKTAKING = "remove_stocktaking",
}
